
import React, {useRef, useLayoutEffect, useState} from 'react';
import * as d3 from 'd3';
import { Box, Button } from '@material-ui/core';
import { select } from 'd3';

const isEmpty = obj => obj.constructor === Object && Object.keys(obj).length === 0;

const isUndefined = obj => typeof obj === 'undefined' || obj === null || obj === undefined;
        
const removeWords = ['Positional - ', '_new', "Physical Activity - ", 'Physical Activity / ', " -", " /", " abroad", " a day"]

const replaceWords= {"/":" ", "Up to ":"<", "up to ":"<", "more than ":">"}

/**
 * Parser for the SAV data to a format that is readable by D3 Pack Layout.
 * It takes also into account the thesholds for values and child counts.
 * 
 * @param {Array(Object)} data: raw json file with SAVs and counts 
 * @param {Object} config: configuration for parsing the data
 * @returns {Array(Object)} nodes with Id and ParentId, and value for leaves
 */
const parseData = (data, config) => {
    if (data.length===0){ return [] }
    let sizeAvg = 0;
    let rootName = data[0][config.symptomAttr],
        nodes = [],
        parents = {};

    /* create the nodes for all the children (leaves) */
    data.forEach((d)=>{
        if (isUndefined(d[config.valueAttr]) || d[config.sizeAttr] < config.valueThreshold){
            return;
        }
        if (isUndefined(parents[d[config.attrubuteAttr]])){
            parents[d[config.attributeAttr]] = true;
        }
        nodes.push({
            'id': d[config.valueAttr],
            'parentId': d[config.attributeAttr],
            'size': +d[config.sizeAttr], 
            'type':'child'
        });
        sizeAvg+=+d[config.sizeAttr]
    });
    sizeAvg /= data.length;

    /* filter out the children using childCountThreshold */
    if (config.childCountThreshold){
        for (let par in parents){
            let children = nodes.filter(c => par === c.parentId).sort((a,b)=>a.size > b.size ? -1 : 1);
            children.forEach((d,i)=>{ d['counter']=i+1});
        }
        nodes = nodes.filter(n => n.counter < config.chilCountThreshold);
    }

    /* add the parents to the nodes, under the root as their parent */
    for (let key in parents){
        nodes.push({
            'id': key,
            'parentId':rootName,
            'type':'parent'
        });
        nodes.push({
            'id': key.toUpperCase(),
            'parentId':key,
            'size':sizeAvg,
            'type':'parentLabel'
        });
    }
    /* add the root */
    nodes.push({'id':rootName,'parentId':'', 'type':'root'})
    return nodes;
}

/**
 * Creates an inisght-component after a bubble is selected from the selection and the insights data
 * 
 * @param {Array(Object)} selection: array of selected objects, same as the original data
 * @param {Object} config: the main configuration object 
 * @param {Object} insights: the raw JSON file with all the insights corresponding to a selection
 *  
 */
const InsightBox = ({selection, config, insights}) => {
    if (isEmpty(selection) || isEmpty(config) || isEmpty(insights)) { return <div></div> }
    let insight = {}
    insights.forEach(ins=>{
        if (ins[config.valueAttr]===selection.data.id && 
            ins[config.attributeAttr]===selection.parent.data.id){
                insight = ins;
        }
        
    }) 
    let diagnoses = insight.diagnoses || "";
    diagnoses = diagnoses
        .replace('["','')
        .replace('"]','')
        .split('\",\"')
        .map(d=>d.charAt(0).toUpperCase() + d.slice(1).toLowerCase());

    let classBox = 'content-box content-box-desktop'
    let contentBoxText = 'content-box-text content-box-text-desktop'
    let contentBoxValue = 'content-box-value content-box-value-desktop'
    let contentBoxButton = 'content-box-button content-box-button-desktop'
    let contentBoxButtonConditions = 'content-box-button content-box-button-conditions-desktop'

    if(config.width < 494) {
        classBox = 'content-box content-box-mobile'
        contentBoxText = 'content-box-text content-box-text-mobile'
        contentBoxValue = 'content-box-value content-box-value-mobile'
        contentBoxButton = 'content-box-button content-box-button-mobile'
        contentBoxButtonConditions = 'content-box-button content-box-button-conditions-mobile'
    } 

    return <Box className={classBox}>
        {config.insightAttrs.map((attr, i)=>{
           
            return <Box key={"_"+i} style={{display:"flex", justifyContent: 'center', flexDirection:"column", backgroundColor:'white'}}> 
                <Box style={{display:"flex", justifyContent: 'center', flexDirection:"row", alignItems: 'center'}}>
                    <Box style={{display:"flex", width:'30%', justifyContent:'flex-end', marginRight: "10px"}}>
                        <p className={contentBoxValue}>
                            {(insight[attr.attr] || "") + (attr.unit || "")}
                        </p>
                    </Box>
                    <Box className={contentBoxText}>
                        {attr.label || ""}
                    </Box>
                </Box>
            </Box>})
        }

        <Box style={{display:'flex', width:'100%', justifyContent:'space-between', alignItems:'center', flexFlow: 'row wrap', color:'#003355', paddingTop:"9px", borderTop:"1px solid #EEEEEE", marginTop:"17px" }}>
            <Button className={contentBoxButton} onClick={() => { goToTreatmentHeadache() }}>
                {"Get treatment"}
            </Button>
            <Button className={contentBoxButtonConditions} onClick={() => { createDiagnosesLinksHeadache(diagnoses) }}>
                {"Top conditions"}<svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" 
                                          clip-rule="evenodd" 
                                          d="M8.29289 5.29289C8.68342 4.90237 9.31658 4.90237 9.70711 5.29289L15.7071 11.2929C16.0976 11.6834 16.0976 12.3166 15.7071 12.7071L9.70711 18.7071C9.31658 19.0976 8.68342 19.0976 8.29289 18.7071C7.90237 18.3166 7.90237 17.6834 8.29289 17.2929L13.5858 12L8.29289 6.70711C7.90237 6.31658 7.90237 5.68342 8.29289 5.29289Z" 
                                          fill="#2B8FFF"/>
                                 </svg>
            </Button>
        </Box>
    </Box>
}

function createDiagnosesLinksHeadache(diagnoses) {
    var element = document.getElementById("diagnoses");
    element.classList.remove("hide-diagnoses");

    let diagnosesLength = diagnoses.length;

    diagnoses.forEach((diag, j) => {
        var element = document.getElementById("diagnose"+j);
        element.innerText = diag;
        element.parentNode.style.display = "block";
    })

    if(diagnosesLength < 6) {
        for (let index = diagnosesLength; index < 6; index++) {
            var element = document.getElementById("diagnose"+index);
            element.innerText = '';
            element.parentNode.style.display = "none";
        }
    }
}

function goToTreatmentHeadache() {
    window.location.href = document.getElementById("diagnoses-box-link").href
}
/**
 * Draw the base layout of the bubbles using a pack layout from d3 in the a container.
 * Facilitates also the the interaction: navigation and selection within the pack layout
 * 
 * @param {Object} widget: reference object (useRef) of the contain to draw the bubbles into
 * @param {Array(Object)} base: the raw json of all the objects (SAVs) to be drawn
 * @param {Object} config; the configuration object with attribute names and sizes to render
 * @param {Function} setSavSelection: callback function when an object is selected
 * 
 */
const drawBase = (widget, base, config, setSavSelection) =>{

    d3.select(widget).selectAll('*').remove();

    const colorScale = d3.scaleOrdinal(config.colors);

    /* set up the data and layout */
    let selection = {};
    const rawNodes = parseData(base, config);
    const treeData = d3.stratify()(rawNodes);

    const layout = d3.pack().size([config.width, config.height]).padding(config.padding);
    const root = d3.hierarchy(treeData).sum(function (d) { return d.data.size; });
    const nodes = root.descendants();
    let focus = root;
    let view;
    layout(root);

    /* the svg to hold the bubbles */
    
    const svg = d3.select(widget)
        .append('svg')
        .attr('width', config.width + config.margin * 2)
        .attr('height', config.height + config.margin * 2)
        .style("background-color",config.backgroundColor)
        .style("border", "1px solid "+config.backgroundColor)
        .on("click", () => {
            let event = d3.event;
            zoom(event, root)
        })
        .append('g')
        .attr('transform',`translate(${config.margin+config.width/2},${config.margin+config.height/2})`);
        
    /* all the internal circles and click even for selection */
    const node = svg.append("g")
        .selectAll("circle")
        .data(root.descendants().slice(0))
        .join("circle")
        .attr("class","savCircle")
        .attr("class",d => {
            if(d.data.data.type==='child') {
                return "child-circle"
            }
        })
        .style("fill", d => {
            if (d.data.data.type==='parentLabel'){
                return "none"
            } else if (d.data.data.type==='parent'){
                return colorScale(d.data.data.id)
            } else if (d.data.data.type==='child') {
                return colorScale(d.data.data.parentId)
            }
            return "#fff";
        })
        .style("fill-opacity",d=>{
            return d.data.data.type==='root' ? 1 : 
                d.data.data.type==='parent' ? 0.2 : 
                d.data.data.type==='child' ? 0.4 : 0
        })
        .on("click", (d) => {
            let event = d3.event;
            return select(d, event)    
        });
    
    /* labelling the bubbles  */
    const label = svg.append("g")
        .style("font", "12px sans-serif")
        .attr("pointer-events", "none")
        .attr("text-anchor", "middle")
        .selectAll("text")
        .data(root.descendants())
        .join("text")
        .attr("class","childLabel")
        .style("fill-opacity", d => d.parent === root ? 1 : 0)
        .style("display", d => d.parent === root ? "inline" : "none")
        .style("fill","#000")
        .style("pointer-events",'none')
        .style("text-anchor",'middle')
        .text(d => {
            let text = d.data.id;
            removeWords.forEach(w=>{
                text = text.replace(w, "");
            })
            for (let repl in replaceWords){
                text = text.replace(repl, replaceWords[repl]);
            }
            return d.depth===1 ? text.toUpperCase() : text;
        });
    
    /* handle zooming to a specific node */
    const zoomTo = (v) => {
        const k = config.width / v[2];
    
        view = v;
        
        label.attr("transform", d => `translate(${(d.x - v[0]) * k},${(d.y - v[1]) * k})`);
        node.attr("transform", d => `translate(${(d.x - v[0]) * k},${(d.y - v[1]) * k})`);
        node.attr("r", d => d.r * k);
    }
    
    /* handle general zooming event */
    const zoom = (event, d) => {
        const focus0 = focus;
    
        focus = d;
    
        const transition = svg.transition()
            .duration(event.altKey ? 7500 : 750)
            .tween("zoom", d => {
                const i = d3.interpolateZoom(view, [focus.x, focus.y, focus.r * 2]);
                return t => zoomTo(i(t));
            });
    
        label
            .filter(function(d) { return d.parent === focus || this.style.display === "inline"; })
            .transition(transition)
            .style("fill-opacity", d => d.parent === focus ? 1 : 0)
            .on("start", function(d) { if (d.parent === focus) this.style.display = "inline"; })
            .on("end", function(d) { if (d.parent !== focus) this.style.display = "none"; });
    }

    /* handle the click -> selection or navigation event */
    const select = (d, event) => {
        setSavSelection({}, true)
        svg.selectAll("circle").style("fill-opacity", t => {
            return t.data.data.type==='root' ? 1 : 
                t.data.data.type==='parent' ? 0.2 : 
                t.data.data.type==='child' ? 0.4 : 0
        })
        if (!d.children){ // leaf is clicked - > possible selection
            if (focus.data.depth === 1){ // at zoomed in stage -> it is selection
                if (focus.data.id === d.data.parent.id){ // node is child of the focus -> perform the selection
                    selection = selection === d ? {} : d // select or unselect when clicked a second time (design?)
                    setSavSelection(selection, selection === d ? false : true); // callback function -> will trigger insights
                    d3.select(event.target).style("fill-opacity", t => {
                        return isEmpty(selection) ? 0.4 : 1;
                    })
                } 
                event.stopPropagation()
            } else { // node is not within focus -> zoom to its parent
                return focus !== d.parent && (zoom(event, d.parent), event.stopPropagation())
            }
        } else { // node is a parent, zoom in or out
            return focus !== d && (zoom(event, d), event.stopPropagation())
        }     
    }

    zoomTo([root.x, root.y, root.r * 2]);

}

/* main component of the pack layout */
const SAVViewer = ({base={}, insights={}, config={}, width=800}) => {
    const baseWidgetRef = useRef(null);
    const [selection, setSelection] = useState({});
    const [addText, setAddText] = useState(true);
    const [classBoxTop, setClassBoxTop] = useState('content-box-top content-box-desktop');
    const [contentBoxTextBubble, setContentBoxTextBubble] = useState('content-box-text content-box-text-desktop content-box-text-center');
    const [contentBoxButtonBubble, setContentBoxButtonBubble] = useState('content-box-button content-box-button-desktop');

    useLayoutEffect(()=> {
        const setSavSelection = (sel, addText) => {
            setSelection(sel)
            setAddText(addText)
        }
        if (!isEmpty(base) && !isEmpty(insights)){
            config.width = Math.min(width, 500) - config.margin * 2;
            config.height = config.width;
            drawBase(baseWidgetRef.current, base, config, setSavSelection);
        }

        if(config.width < 494) {
            setContentBoxTextBubble('content-box-text content-box-text-mobile content-box-text-center')
            setContentBoxButtonBubble('content-box-button content-box-button-mobile')
            setClassBoxTop('content-box-top content-box-mobile')
        } 
    },[base, insights, config] );

    
    return <Box>
        <Box ref={baseWidgetRef}></Box>
        <InsightBox selection={selection} config={config} insights={insights}></InsightBox>
        <Box style={{display: addText? 'block' : 'none'}} className={classBoxTop}> 
            <Box className={contentBoxTextBubble}>
                {'Tap a bubble to explore'}
            </Box>
            <Box style={{display:'flex', width:'100%', justifyContent:'center', alignItems:'center', flexFlow: 'row wrap', color:'#003355', paddingTop:"9px", borderTop:"1px solid #EEEEEE", marginTop:"17px" }}>
                <Button className={contentBoxButtonBubble} onClick={() => { goToTreatmentHeadache() }}>
                    {"Get treatment"}
                </Button>
            </Box>
        </Box>
    </Box>
}

export default SAVViewer;