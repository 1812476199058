import './App.css';
import SAVViewer from "./SAVViewer";
import dataHeadache from "./Headache.json";
import dataGential from "./Genital Discharge - Vagina.json";
import dataVaginalBleeding from "./Vaginal Bleeding.json";
import dataRibPain from "./Rib Pain.json";
import dataAnxiety from "./Anxiety.json";

function App() {
  const config = {
    "margin": 3,
    "opacity": 1.0,
    "symptomAttr": "sym_name",
    "attributeAttr": "attribute_name",
    "valueAttr": "value_name",
    "sizeAttr": "total",
    "valueThreshold": 10,
    "colors": [
      '#FFCE43',
      '#F2A0A0',
      '#E5D4E5',
      '#8EF3D0',
      '#F9B290',
      '#B0DDFF'
    ],
    "backgroundColor": "#EBF4FF",
    "selectedColor": "#4A8DF7",
    "padding": 5,
    "insightAttrs": [
      {
        "label": "Were treated by a doctor with medication (KMD-Rx)",
        "attr": "rx_prescription_rate",
        "unit": "%"
      }
    ]
  }
  const width = window.innerWidth - (window.innerWidth*0.2)

  var element = document.getElementById("widgetType");

  switch (element.value) {
    case 'Headache':
      var data =  dataHeadache
      break;
    case 'Gential Discharge':
      var data =  dataGential
      break;
    case 'Vaginal Bleeding':
      var data =  dataVaginalBleeding
      break;
    case 'Rib Pain':
      var data =  dataRibPain
      break;
    case 'Anxiety':
      var data =  dataAnxiety
      break;
    default:
      var data =  dataGential
      break;
  }

  return (
    <div className="App">
      <SAVViewer
          base={data.base}
          insights={data.insights}
          config={config}
          width={width}
      />
    </div>
  );
}

export default App;